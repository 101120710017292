export class UiUser {

  jwtString: string;
  jwtToken;
  userId: string;
  userEmail: string;
  expiration: Date;

}

export class UserSettings {
  uid: number;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  phoneNumber: string;
  cellPhoneNumber: string;
  faxNumber: string;
  jobTitle: string;
  active: number;
  lastApp: number;
  lastPID: number;
  loginCount: number;
  lastLogin: Date;
  prevLogin: Date;
  isMHIUser: boolean;
  defaultPkgs: number;
  displayHeight: number;
  virtualScrolling: number;
  eid: string;
  dateAdded: Date;
  profileUpdated: Date;
  macroManagerQuickAccessPageID: number;
  macroManagerQuickAccessPageUrl: string;
  architect340BQuickAccessPageID: number;
  architect340BQuickAccessPageUrl: string;
  gatewayQuickAccessPageID: number;
  gatewayQuickAccessPageUrl: string;
  mfaPreference: string;
  remainingDays: number;
  lastloggedin: number;
  mfrAudit: number;
  display340BMsg: number;
}

export class UserSession {
  uid: number;
  pid: number;
  pharmName: string;
  pharmName340B: string;
  hid: number;
  hospitalName: string;
  vid: number
  pharmActive: number;
  pharmClass: number;
  pharmType: number;
  pharmNDCA: number;
  ceOwned: number;
  beta: number
  pharmInfo: string;
  emailSetting: number;
  splitSequence: string;
  defaultStatus: string;
  recalculated: string;
  isSpecialty: number;
  isGateway: number;
  isPAP: number;
  isNDCA: number;
  ndcPharmacyInfo: NDCAPharmacy;
  mckBiAccess: any;
  isFeasibility: number;
  corpID: number;
  corpName: string;
  chainGroupId: number;
  chainGroupName: string;
  analyticsPid: number;
  vrrEnabled: number;
}

export class SaveUserSettings {
  defaultPkgs: number;
  displayHeight: number;
  virtualScrolling: number;
}

export class SaveUserSession {
  uid: number;
  pid: number;
}

export class UserEmailSettings {
  emailSettingID: number;
  email850MyOrders: boolean;
  email850AutoOrders: boolean;
  email850OrderBuilder: boolean;
  email850FileDropOrders: boolean;
  email855Confirmation: boolean;
}

export class SaveUserQuickAccessPage {
  appId: number;
  accessAction: string;
  pageId: number;
  quickAccessPageUrl: string;
}

export class SaveUsersTrackHistory {
  userId: number;
  pid: number;
  applicationId: number;
  pageUrl: string;
  isHelpPageTrack: number;
}

export class SaveUserClass {
  uid: number;
  email: string;
  lastName: string;
  firstName: string;
  phoneNumber: string;
  cellPhoneNumber: string;
  faxNumber: string;
  jobTitle: string;
  lastApp: number;
  lastPid: number;
  loginCount: number;
  lastLogin: Date;
  prevLogin: Date;
  isMHIUser: boolean;
  defaultPkgs: number;
  displayHeight: number;
  virtualScrolling: number;
  eid: string;
  active: number;
  profileUpdated: Date;
  mfaPreference: string;
}

export class PageInfo {
  pageId: number;
}


export class UserNotificationSettings {
  DataAlerts: boolean;
  HospitalPharmacyRegulatoryNotices: boolean;
  NDCArchitectReleaseNotes: boolean;
  OrderDelayOutage: boolean;
  OrderSubmittedtoWholesalerNotices: boolean;
  PharmacySettingChangeAlerts: boolean;
  RetailAutomaticReglatoryNotices: boolean;
  RetailPharmacyRegulatoryNotices: boolean;
  UserGroupInvites: boolean;
  WholesalerConfirmationReceivedNotices: boolean;
}

export class SaveUserMHISettings {
  usersMHISettingID: string;
  uid: number;
  mhiTier: number;
  vpnAccess: number;
  dbAccess: number;
  updatedBy: number;
}

export class UsersPublicationSelection {
  publicationID: number;
  name: string;
  description: string;
  active: number;
  selected: number;
}

export class SaveUserPubicationSelection {
  uid: number;
  publicationID: number;
  isSelected: number;
}

export class UserAlternateLoginName {
  uvid: number;
  vendorName: string;
  dateAdded: Date;
  loginName: string;
}
export class UserApplications {
  applicationList: number[];
  lastApp: number;
  lastPid: number;
}
export class UserApplicationPage {
  pageID: number;
  ngPageURL: string;
  appID: number;
}
export class UAMUserApplicationPage {
  pageID: number;
  sortOrder: number;
  ngPageURL: string;
  appID: number;
  permissionKey: string;
  write: boolean;
}
export class UserApplicationInfo extends UserApplications {
  applicationPages: UAMUserApplicationPage[];
}

export class UserAccessToken {
  token: string;
}

export class UserAccessTokenInfo {
  userId: number;
  name: string;
  userEmployee: boolean;
  lastApp: number;
  lastPID: number;
  pharmType: number;
  department: number;
  favoritePage: number;
  defaultPkgs: number;
  displayHeight: number;
  appMenu: number;
}

export class NotificationItem {
  posted: Date;
  notification: string;
  dateViewed: Date;
  notificationAlertType: string;
  notificationID: number;
  noteSentID: number;
}

export class UpdateNotification {
  //updateNotificationID: number;
  updateNoteSentID: number;
  updatedNotificationList: NotificationItem[];
}

export class Vendor {
  vendorName: string;
  vendorID: number;
}

export class AddUserAlternateLoginName {
  uid: number;
  vendorId: number;
  loginName: string;
  vid: number;
  active: number;
  addedBy: number;
}

export class NotificationItemHistory {
  posted: string;
  notification: string;
  dateViewed: Date;
  notificationAlertType: string;
  notificationID: string;
  noteSentID: string;

}

export class NDCAPharmacy {
  ndcArchID: number;
  pid: number;
  active: number;
  effective: Date;
  terminated: Date;
  filenameFormat: string;
  outboxFolder: string;
  emailContacts: string;
  billable: number;
  notes: string;
  dateAdded: Date;
  addedBy: number;
  billableDate: Date;
  piD340B: number;
  stateFee: number;
  billableBy: number;
  updatedBy: number;
  dateUpdated: Date;
  finalBillingInstall: number;
  addedByName: string;
}

export class DisclaimerItem {
  disclaimerMessage: string;
  disclaimerID: number;
}

export class B2CUserState {
  acr: string;
  email: string;
  upn: string;
  exp?: Date;
  tid: string;
}

export class CSGID {
  csgid: string;
  name: string;
}
export class LookerUrl {
  embedUrl: string;
}

export class UserTokenRequest {
  pid: number;
  clientId: number;
  isCorp: boolean;
}

export enum PermissionType {
    NoAccess = 0,
    CanRead = 1,
    CanChange = 2
}
