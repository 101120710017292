import { Injectable } from '@angular/core';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PendingPackagesService } from 'src/app/service/pending-packages.service';
import { ApplyPackagesNdc, ApplyPackagesNdcErr, ApplyPackagesNdcResults, ApplyPackagesPid, ApplyPackagesPidErr, ApplyPackagesPidResults, GetAccumulationsByPID, GetAccumulationsByPIDError, GetAccumulationsByPIDResults, GetDrugCatalogByPID, GetDrugCatalogByPIDError, GetDrugCatalogByPIDResults, GetPackagesByHid, GetPackagesByHidError, GetPackagesByHidResults, GetPackagesByNdc, GetPackagesByNdcError, GetPackagesByNdcResults, PackagesShowPid, PackagesShowPidError, PackagesShowPidResults } from './pending-packages.actions';

@Injectable()
export class PendingPackagesEffects {
  constructor(private pendingPackagesService: PendingPackagesService, private actions$: Actions) { }

  GetPackagesByHidEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetPackagesByHid),
    switchMap(() =>
      this.pendingPackagesService.getPendingPackagesByHid()
        .pipe(
          map(response => GetPackagesByHidResults({ packagesByHid: response })),
          catchError(error => {
            return observableOf(GetPackagesByHidError({ errorMessage: error }))
          })
        )
    )
  ));

  GetPackagesByNdcEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetPackagesByNdc),
    switchMap(() =>
      this.pendingPackagesService.getPendingPackagesByNdc()
        .pipe(
          map(response => GetPackagesByNdcResults({ packagesByNdc: response })),
          catchError(error => {
            return observableOf(GetPackagesByNdcError({ errorMessage: error }))
          })
        )
    )
  ));

  GetAccumulationsByPIDEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetAccumulationsByPID),
    switchMap((action) =>
      this.pendingPackagesService.getAccumulationsByPID(action.pageSize, action.pageIndex)
        .pipe(
          map(response => GetAccumulationsByPIDResults({ accumulations: response })),
          catchError(error => {
            return observableOf(GetAccumulationsByPIDError({ errorMessage: error }))
          })
        )
    )
  ));

  GetDrugCatalogByPIDEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetDrugCatalogByPID),
    switchMap((action) =>
      this.pendingPackagesService.getDrugCatalogByPID(action.pageSize, action.pageIndex)
        .pipe(
          map(response => GetDrugCatalogByPIDResults({ drugCatalog: response })),
          catchError(error => {
            return observableOf(GetDrugCatalogByPIDError({ errorMessage: error }))
          })
        )
    )
  ));

  PackagesShowPidEffect$ = createEffect(() => this.actions$.pipe(
    ofType(PackagesShowPid),
    switchMap(() =>
      this.pendingPackagesService.packagesShowPid()
        .pipe(
          map(response => PackagesShowPidResults({ pidList: response })),
          catchError(error => {
            return observableOf(PackagesShowPidError({ errorMessage: error }))
          })
        )
    )
  ));

  ApplyPackageHoursPidEffect$ = createEffect(() => this.actions$.pipe(
    ofType(ApplyPackagesPid),
    switchMap(a =>
      this.pendingPackagesService.applyPackageHoursPid(a.assignPackagesPidRequest)
        .pipe(
          map(response => ApplyPackagesPidResults({ applyPackagesPidResponse: response })),
          catchError(error => {
            return observableOf(ApplyPackagesPidErr({ errorMessage: error }))
          })
        )
    )
  ));

  ApplyPackageHoursNdcEffect$ = createEffect(() => this.actions$.pipe(
    ofType(ApplyPackagesNdc),
    switchMap(a =>
      this.pendingPackagesService.applyPackageHoursNdc(a.assignPackagesNdcRequest)
        .pipe(
          map(response => ApplyPackagesNdcResults({ applyPackagesNdcResponse: response })),
          catchError(error => {
            return observableOf(ApplyPackagesNdcErr({ errorMessage: error }))
          })
        )
    )
  ));
}

