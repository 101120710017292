import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { CSGID } from "src/app/models/user";
import { UserState } from "src/app/store/user.reducer";
import {
  GetCSGIDList,
  GetSelectedCSGID,
} from "src/app/store/user.actions";
import { concat, Subscription } from "rxjs";
import {
  GetGatewayChain
} from "src/app/store/gateway/gateway.actions";
import { GatewayState } from "src/app/store/gateway/gateway.reducer";
import { filter } from "rxjs/operators";
import { HideGatewayCsgids, ShowClientSelection, ShowGatewayCsgids } from 'src/app/store/user.actions';
import { ErrShow } from "src/app/store/ui-state.actions";

@Component({
  selector: "app-csgid-selector",
  templateUrl: "./csgid-selector.component.html",
  styleUrls: ["./csgid-selector.component.scss"],
})
export class CsgidSelectorComponent implements OnInit {
  private subscriptions = new Subscription();
  selectedCSGID: string;
  selectedCSGIDName: string;
  userAssignedCSGIDs: CSGID[];

  constructor(private store: Store<{ userState: UserState, gatewayState: GatewayState }>) {
  }

  ngOnInit(): void {
    //Scenario where user logs back in with a token and didn't select anything from client-selection screen
    this.store
      .select((state) => state.userState.session)
      .subscribe((session) => {
        if (session.chainGroupId) {
          this.selectedCSGID = session.chainGroupId.toString();
          this.selectedCSGIDName = session.chainGroupName;
          this.store.dispatch(GetSelectedCSGID({
            csgId: this.selectedCSGID,
            name: this.selectedCSGIDName
          }));
          this.validateGatewayCSGID();
        }
      });

    //Get assigned CSGIDs
    this.store.dispatch(GetCSGIDList());
    this.subscriptions.add(
      this.store
        .select((state) => state.userState.csgids)
        .pipe(filter((csgids) => csgids !== null))
        .subscribe((csgids) => {
          this.userAssignedCSGIDs = csgids;
          //If user only has 1, select it by default
          if (this.userAssignedCSGIDs.length == 1 && this.userAssignedCSGIDs[0].csgid != null) {
            this.selectedCSGID = this.userAssignedCSGIDs[0].csgid;
            this.selectedCSGIDName = this.userAssignedCSGIDs[0].name;
            this.store.dispatch(GetSelectedCSGID({
              csgId: this.selectedCSGID,
              name: this.selectedCSGIDName
            }));
          }
          this.validateGatewayCSGID();
        })
    );

    // Routed back from clientID-Selection component
    this.subscriptions.add(
      this.store.select(state => state.userState.selectedCSGID).subscribe(stateSelectedCSGID => {
        if (stateSelectedCSGID) {
          this.store.dispatch(HideGatewayCsgids());
          this.selectedCSGID = stateSelectedCSGID.csgid;
          this.selectedCSGIDName = stateSelectedCSGID.name;

          // Scenario where user chooses anything but a valid GW CSGID
          if (this.selectedCSGID == '-1')
            this.showError();
          else
            this.validateGatewayCSGID();
        }
      })
    );
  }

  validateGatewayCSGID() {
    if (this.selectedCSGID == null || this.userAssignedCSGIDs == null)
      return;

    //Make sure CSGID is Gateway
    let isGatewayCSGID: boolean = false;
    this.userAssignedCSGIDs.find(CSGID => {
      if (CSGID.csgid == this.selectedCSGID) {
        isGatewayCSGID = true;
      }
    });

    if (isGatewayCSGID) {
      //Set current selected chain for left menu
      this.store.dispatch(GetGatewayChain({ CSGID: parseInt(this.selectedCSGID) }));
    }
    else {
      this.showError();
    }
  }

  showError() {
    this.store.dispatch(ErrShow({ message: "No Gateway PIDs are assigned to the User.", }));
  }

  openClientSelection() {
    this.store.dispatch(ShowGatewayCsgids());
    this.store.dispatch(ShowClientSelection());
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
